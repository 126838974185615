@font-face {
  font-family: sandals-icon-font;
  src: url(/sandals/fonts/sandals-icon-font.woff) format("woff"),
  url(/sandals/fonts/sandals-icon-font.ttf) format("truetype"),
  url(/sandals/fonts/sandals-icon-font.eot) format("embedded-opentype"),
  url(/sandals/fonts/sandals-icon-font.svg?v=ubf60ace8#sandals) format("svg");
  font-weight: 400;
  font-style: normal
}

.ic {
  font-family: sandals-icon-font !important;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.ic-star:before {
  content: "\e902"
}

.ic-avatar:before {
  content: "\e903"
}

.ic-info-new:before {
  content: "\e901"
}

.ic-tranquility-soaking-tub:before {
  content: "\e900"
}

.ic-bus:before {
  content: "\e904"
}

.ic-cocktail-shaker:before {
  content: "\e905"
}

.ic-dumbbell:before {
  content: "\e906"
}

.ic-fork-knife-stars:before {
  content: "\e907"
}

.ic-half-full-wine-glass:before {
  content: "\e908"
}

.ic-instructions-board:before {
  content: "\e909"
}

.ic-liquors-fridge:before {
  content: "\e90a"
}

.ic-martini-glass:before {
  content: "\e90b"
}

.ic-money-stack:before {
  content: "\e90c"
}

.ic-snorkle-mask:before {
  content: "\e90d"
}

.ic-tennis-player-ball:before {
  content: "\e90e"
}

.ic-theater-masks:before {
  content: "\e90f"
}

.ic-wedding-rings:before {
  content: "\e910"
}

.ic-lovenest-butler-bottom:before {
  content: "\e911"
}

.ic-pina:before {
  content: "\e912"
}

.ic-accommodations:before {
  content: "\e913"
}

.ic-grid-view-o:before {
  content: "\e914"
}

.ic-button-arrow-down:before {
  content: "\e915"
}

.ic-button-arrow-next:before {
  content: "\e916"
}

.ic-button-arrow-prev:before {
  content: "\e917"
}

.ic-calendar:before {
  content: "\e918"
}

.ic-camera-o:before {
  content: "\e919"
}

.ic-camera:before {
  content: "\e91a"
}

.ic-chat-with-us:before {
  content: "\e91b"
}

.ic-chat-with-us-es:before {
  content: "\e91c"
}

.ic-check:before {
  content: "\e91d"
}

.ic-compare:before {
  content: "\e91e"
}

.ic-dropdown-arrows:before {
  content: "\e91f"
}

.ic-exuma:before {
  content: "\e920"
}

.ic-facebook:before {
  content: "\e921"
}

.ic-globe:before {
  content: "\e922"
}

.ic-golf:before {
  content: "\e923"
}

.ic-google-plus:before {
  content: "\e924"
}

.ic-instagram:before {
  content: "\e925"
}

.ic-link-arrow:before {
  content: "\e926"
}

.ic-logo-air-canada:before {
  content: "\e927"
}

.ic-logo-american-airlines:before {
  content: "\e928"
}

.ic-logo-bahamasair:before {
  content: "\e929"
}

.ic-logo-beaches:before {
  content: "\e92a"
}

.ic-logo-butler-elite:before {
  content: "\e92b"
}

.ic-logo-delta:before {
  content: "\e92c"
}

.ic-logo-fowl-cay:before {
  content: "\e92d"
}

.ic-logo-seb:before {
  content: "\e92e"
}

.ic-logo-global-gourmet:before {
  content: "\e92f"
}

.ic-logo-grand-pineapple:before {
  content: "\e930"
}

.ic-logo-jamaican-villas:before {
  content: "\e931"
}

.ic-logo-sandals:before {
  content: "\e932"
}

.ic-logo-sandals-foundation:before {
  content: "\e933"
}

.ic-logo-seb-golf:before {
  content: "\e934"
}

.ic-logo-silver:before {
  content: "\e935"
}

.ic-marker-o:before {
  content: "\e936"
}

.ic-plane:before {
  content: "\e937"
}

.ic-planner:before {
  content: "\e938"
}

.ic-reading:before {
  content: "\e939"
}

.ic-restaurants:before {
  content: "\e93a"
}

.ic-search:before {
  content: "\e93b"
}

.ic-specials:before {
  content: "\e93c"
}

.ic-twitter:before {
  content: "\e93d"
}

.ic-weddings:before {
  content: "\e93e"
}

.ic-create-your-wedding:before {
  content: "\e93f"
}

.ic-check-2:before {
  content: "\e940"
}

.ic-info:before {
  content: "\e941"
}

.ic-reset:before {
  content: "\e942"
}

.ic-close:before {
  content: "\e943"
}

.ic-logo-butler:before {
  content: "\e944"
}

.ic-logo-club-sandals:before {
  content: "\e945"
}

.ic-logo-lovenest:before {
  content: "\e946"
}

.ic-butler:before {
  content: "\e947"
}

.ic-lovenest:before {
  content: "\e948"
}

.ic-private-transfers:before {
  content: "\e949"
}

.ic-room-service:before {
  content: "\e94a"
}

.ic-wi-fi:before {
  content: "\e94b"
}

.ic-grid-view:before {
  content: "\e94c"
}

.ic-list-view:before {
  content: "\e94d"
}

.ic-logo-sandals-luxury:before {
  content: "\e94e"
}

.ic-refine:before {
  content: "\e94f"
}

.ic-logo-seb-exuma:before {
  content: "\e950"
}

.ic-cabana:before {
  content: "\e951"
}

.ic-entertainment:before {
  content: "\e952"
}

.ic-landsports:before {
  content: "\e953"
}

.ic-red-lane-spa:before {
  content: "\e954"
}

.ic-running:before {
  content: "\e955"
}

.ic-scuba:before {
  content: "\e956"
}

.ic-tours:before {
  content: "\e957"
}

.ic-watersports:before {
  content: "\e958"
}

.ic-yacht:before {
  content: "\e959"
}

.ic-download:before {
  content: "\e95a"
}

.ic-marker:before {
  content: "\e95b"
}

.ic-pinterest:before {
  content: "\e95c"
}

.ic-soaking-tub:before {
  content: "\e95d"
}

.ic-youtube:before {
  content: "\e95e"
}

.ic-bag:before {
  content: "\e95f"
}

.ic-butler2:before {
  content: "\e960"
}

.ic-call:before {
  content: "\e961"
}

.ic-card:before {
  content: "\e962"
}

.ic-laundry:before {
  content: "\e963"
}

.ic-lock:before {
  content: "\e964"
}

.ic-luggage:before {
  content: "\e965"
}

.ic-medical:before {
  content: "\e966"
}

.ic-money:before {
  content: "\e967"
}

.ic-money2:before {
  content: "\e968"
}

.ic-passport:before {
  content: "\e969"
}

.ic-safe:before {
  content: "\e96a"
}

.ic-smartphone:before {
  content: "\e96b"
}

.ic-transfers:before {
  content: "\e96c"
}

.ic-weather:before {
  content: "\e96d"
}

.ic-wheelchair:before {
  content: "\e96e"
}

.ic-hanger:before {
  content: "\e96f"
}

.ic-outlet:before {
  content: "\e970"
}

.ic-foundation:before {
  content: "\e971"
}

.ic-shopping:before {
  content: "\e972"
}

.ic-candlelight:before {
  content: "\e973"
}

.ic-lovenest-stacked:before {
  content: "\e974"
}

.ic-departure-tax:before {
  content: "\e975"
}

.ic-logo-red-lane-spa-white:before {
  content: "\e976"
}

.ic-logo-schooners-white:before {
  content: "\e977"
}

.ic-logo-united:before {
  content: "\e978"
}

.ic-logo-jetblue:before {
  content: "\e979"
}

.ic-logo-southwest:before {
  content: "\e97a"
}

.ic-logo-spirit:before {
  content: "\e97b"
}

.ic-logo-caribbeanairlines:before {
  content: "\e97c"
}

.ic-memorial:before {
  content: "\e97d"
}

.ic-logo-sbd:before {
  content: "\e97e"
}

.ic-stay-and-play:before {
  content: "\e97f"
}

.ic-logo-beachfrontvillas:before {
  content: "\e980"
}

.ic-exchangeprgram:before {
  content: "\e981"
}

.ic-smb-xfun:before {
  content: "\e982"
}

.ic-sandals-south-coast-logo:before {
  content: "\e983"
}

.ic-logo-liat:before {
  content: "\e984"
}

.ic-logo-westjet:before {
  content: "\e985"
}

.ic-logo-ochi:before {
  content: "\e986"
}

.ic-golf-lessons:before {
  content: "\e987"
}

.ic-play-button:before {
  content: "\e988"
}

.ic-golf-shield-logo:before {
  content: "\e989"
}

.ic-la-source-logo:before {
  content: "\e98a"
}

.ic-logo-air-transat:before {
  content: "\e98b"
}

.ic-logo-silver-air:before {
  content: "\e98c"
}

.ic-shc-xfun:before {
  content: "\e98d"
}

.ic-slu-xfun:before {
  content: "\e98e"
}

.ic-ic-activities-snuba:before {
  content: "\e98f"
}

.ic-icon-royalthai-white:before {
  content: "\e990"
}

.ic-icon-earthguard-energy:before {
  content: "\e991"
}

.ic-icon-earthguard-staff:before {
  content: "\e992"
}

.ic-icon-earthguard-substances:before {
  content: "\e993"
}

.ic-icon-earthguard-tours:before {
  content: "\e994"
}

.ic-icon-earthguard-waste:before {
  content: "\e995"
}

.ic-icon-earthguard-water:before {
  content: "\e996"
}

.ic-logo-sandals-earthguard:before {
  content: "\e997"
}

.ic-signature:before {
  content: "\e998"
}

.ic-pinterest-circle:before {
  content: "\e999"
}

.ic-logo-exchange-2x-barbados:before {
  content: "\e99a"
}

.ic-logo-exchange-6x-jamaica:before {
  content: "\e99b"
}

.ic-sandals-tranquility-logo:before {
  content: "\e99c"
}

.ic-seal-tranquility-tub:before {
  content: "\e99d"
}

.ic-logo-ochi-beach-club:before {
  content: "\e99e"
}

.ic-logo-royal-barbados:before {
  content: "\e99f"
}

.ic-logo-emerald-bay-golf:before {
  content: "\e9a0"
}

.ic-logo-cap-estate:before {
  content: "\e9a1"
}

.ic-logo-padi:before {
  content: "\e9a2"
}

.ic-logo-padi-elearning:before {
  content: "\e9a3"
}

.ic-logo-dan:before {
  content: "\e9a4"
}

.ic-sandals-tranquility-sleep:before {
  content: "\e9a5"
}

.ic-icon-free-week:before {
  content: "\e9a6"
}

.ic-icon-dollar-points:before {
  content: "\e9a7"
}

.ic-icon-recognition:before {
  content: "\e9a8"
}

.ic-logo-sandals-select:before {
  content: "\e9a9"
}

.ic-icon-american-sweepstakes:before {
  content: "\e9aa"
}

.ic-icon-bridal-number:before {
  content: "\e9ab"
}

.ic-icon-fitness-centers:before {
  content: "\e9ac"
}

.ic-icon-red-lane-weddings:before {
  content: "\e9ad"
}

.ic-icon-spa-treatments:before {
  content: "\e9ae"
}

.ic-icon-alert:before {
  content: "\e9af"
}

.ic-icon-alreadybooked-before-you-arrive:before {
  content: "\e9b0"
}

.ic-icon-alreadybooked-check-in-online:before {
  content: "\e9b1"
}

.ic-icon-alreadybooked-pay-balance:before {
  content: "\e9b2"
}

.ic-icon-digitalalbum:before {
  content: "\e9b3"
}

.ic-icon-bowling:before {
  content: "\e9b4"
}

.ic-icon-exchange:before {
  content: "\e9b5"
}

.ic-icon-five-star:before {
  content: "\e9b6"
}

.ic-icon-fork:before {
  content: "\e9b7"
}

.ic-icon-golf:before {
  content: "\e9b8"
}

.ic-icon-island:before {
  content: "\e9b9"
}

.ic-icon-overthewater:before {
  content: "\e9ba"
}

.ic-icon-pool-small:before {
  content: "\e9bb"
}

.ic-icon-bar-second:before {
  content: "\e9bc"
}

.ic-icon-sl-xfun:before {
  content: "\e9bd"
}

.ic-logo-aisle-to-isle:before {
  content: "\e9be"
}

.ic-logo-caribbean-travel:before {
  content: "\e9bf"
}

.ic-logo-fortune:before {
  content: "\e9c0"
}

.ic-logo-new-york-post:before {
  content: "\e9c1"
}

.ic-logo-travel-leisure:before {
  content: "\e9c2"
}

.ic-logo-usa-today:before {
  content: "\e9c3"
}

.ic-island-routes:before {
  content: "\e9c4"
}

.ic-loading-icon:before {
  content: "\e9c5"
}

.ic-beer-wine:before {
  content: "\e9c6"
}

.ic-premium-spirits:before {
  content: "\e9c7"
}

.ic-choice-premium-spirits:before {
  content: "\e9c8"
}

.ic-concierge:before {
  content: "\e9c9"
}

.ic-rolls-royce:before {
  content: "\e9ca"
}

.ic-logo-bestbeaches:before {
  content: "\e9cb"
}

.ic-icon-dive:before {
  content: "\e9cc"
}

.ic-logo-clip-yard:before {
  content: "\e9cd"
}

.ic-logo-lovers-lane:before {
  content: "\e9ce"
}

.ic-liv-plus:before {
  content: "\e9cf"
}

.ic-arrow:before {
  content: "\e9d0"
}

.ic-landscape-orientation:before {
  content: "\e9d1"
}

.ic-saved-wishlist:before {
  content: "\e9d2"
}

.ic-share:before {
  content: "\e9d3"
}

.ic-zoom-reset:before {
  content: "\e9d4"
}

.ic-icon-ticket:before {
  content: "\e9d5"
}

.ic-icon-pointer:before {
  content: "\e9d6"
}

.ic-icon-diver:before {
  content: "\e9d7"
}

.ic-icon-drink-2:before {
  content: "\e9d8"
}

.ic-icon-laid-back:before {
  content: "\e9d9"
}

.ic-icon-meeting:before {
  content: "\e9da"
}

.ic-icon-socializer:before {
  content: "\e9db"
}

.ic-icon-tennis-2:before {
  content: "\e9dd"
}

.ic-logo-sandals-tag:before {
  content: "\e9de"
}

.ic-logo-beaches-tag:before {
  content: "\e9df"
}

.ic-expand:before {
  content: "\e9e0"
}

.ic-logo-sunwing:before {
  content: "\e9e1"
}

.ic-icon-play:before {
  content: "\e9e2"
}

.ic-icon-pause:before {
  content: "\e9e3"
}

.ic-logo-oleander:before {
  content: "\e9e4"
}

.ic-icon-candlelight:before {
  content: "\e9e5"
}

.ic-master-craft:before {
  content: "\e9e6"
}

.ic-logo-lifefitness:before {
  content: "\e9e7"
}

.ic-logo-lovenest-stack:before {
  content: "\e9e8"
}

.ic-logo-mastercraft:before {
  content: "\e9e9"
}

.ic-logo-mondavi:before {
  content: "\e9ea"
}

.ic-logo-clubsandals-label:before {
  content: "\e9eb"
}

.ic-logo-lovenest-full:before {
  content: "\e9ec"
}

.ic-label-top5scuba:before {
  content: "\e9ed"
}

.ic-vrx1:before {
  content: "\e9ee"
}

.ic-icon-vrx:before {
  content: "\e9ef"
}

.ic-cube:before {
  content: "\e9f0"
}

.ic-logo-lovenest-butler:before {
  content: "\e9f1"
}

.ic-icon-earthguard-cup:before {
  content: "\e9f2"
}

.ic-dress-code:before {
  content: "\e9f3"
}

.ic-backpack:before {
  content: "\e9f4"
}

.ic-community:before {
  content: "\e9f5"
}

.ic-coral:before {
  content: "\e9f6"
}

.ic-school:before {
  content: "\e9f7"
}

.ic-seeds:before {
  content: "\e9f8"
}

.ic-toothpaste:before {
  content: "\e9f9"
}

.ic-double_arrow:before {
  content: "\e9fa"
}

.ic-vip-arrival:before {
  content: "\e9fb"
}

.ic-club-elite:before {
  content: "\e9fc"
}

.ic-beaches-logo:before {
  content: "\e9fd"
}

.ic-sandals-certified-specialist:before {
  content: "\e9fe"
}

.ic-vrx:before {
  content: "\e9dc"
}

.ic-user:before {
  content: "\e9ff"
}
