:root {
  --q-color-brand1: #04a8e7;
  --q-color-brand2: #99bfd7;
  --q-color-brand3: #85bfe4;
  --q-color-brand4: #2c98ce;
  --q-color-brand5: #146484;
}

body {
  color: #424242;
  font-family: var(--bodyFontFamily);
}

.text-brand1 {
  color: #04a8e7 !important;
}

.bg-brand1 {
  background: #04a8e7 !important;
}

.text-brand2 {
  color: #99bfd7 !important;
}

.bg-brand2 {
  background: #99bfd7 !important;
}

.text-brand3 {
  color: #85bfe4 !important;
}

.bg-brand3 {
  background: #85bfe4 !important;
}

.text-brand4 {
  color: #2c98ce !important;
}

.bg-brand4 {
  background: #2c98ce !important;
}
.bg-brand4 .text-brand5 {
  color: #146484 !important;
}

.bg-brand5 {
  background: #146484 !important;
}

@font-face {
  font-family: FuturaBook;
  src: url(./fonts/futura-book/Futura-Book.woff);
}
.futura-book {
  font-family: "FuturaBook";
}

@font-face {
  font-family: FuturaRegular;
  src: url(./fonts/futura-regular/Futura-Regular.ttf);
}
.futura-regular {
  font-family: "FuturaRegular";
}

.custom-toolbar {
  background-color: #FFFFFF !important;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 1600 !important;
  line-height: 20px;
  letter-spacing: 0.200000003px;
}

.custom-toolbar-title {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.200000003px;
  display: flex;
  align-items: center;
}

.custom-toolbar-title .material-icons {
  margin-right: 10px;
}

.q-layout-container {
  height: 94vh !important;
}