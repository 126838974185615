@import '~src/css/quasar.variables.sass', 'quasar/src/css/variables.sass'
// app global css in Sass form

\:root
  --q-color-brand1: #{$brand1}
  --q-color-brand2: #{$brand2}
  --q-color-brand3: #{$brand3}
  --q-color-brand4: #{$brand4}
  --q-color-brand5: #{$brand5}
body
  color: $grey-9
  font-family: var(--bodyFontFamily)

.text-brand1
  color: $brand1 !important
.bg-brand1
  background: $brand1 !important

.text-brand2
  color: $brand2 !important
.bg-brand2
  background: $brand2 !important

.text-brand3
  color: $brand3 !important
.bg-brand3
  background: $brand3 !important

.text-brand4
  color: $brand4 !important
.bg-brand4
  background: $brand4 !important

  .text-brand5
    color: $brand5 !important
.bg-brand5
  background: $brand5 !important

@font-face
  font-family: FuturaBook
  src: url(./fonts/futura-book/Futura-Book.woff)

.futura-book
  font-family: 'FuturaBook'

@font-face
  font-family: FuturaRegular
  src: url(./fonts/futura-regular/Futura-Regular.ttf)

.futura-regular
  font-family: 'FuturaRegular'


// Ingrative design custom styles 
.custom-toolbar
  background-color: #FFFFFF !important
  font-family: Open Sans
  font-size: 16px
  font-weight: 1600 !important
  line-height: 20px
  letter-spacing: 0.20000000298023224px

.custom-toolbar-title
  font-family: Open Sans
  font-size: 16px
  font-weight: 600
  line-height: 20px
  letter-spacing: 0.20000000298023224px
  display: flex
  align-items: center

.custom-toolbar-title .material-icons 
  margin-right: 10px


.q-layout-container
  height: 94vh !important
